import React, { Component } from "react"
import * as styles from "./howItWorks.module.scss"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
import styled from "styled-components"
import videobg from "../../../../assets/how-video-bg.png"
import videobgmin from "../../../../assets/how-video-bg-min.png"
import ProgressiveImage from "react-progressive-image-loading"
import { TM } from "../../common/typography/trademark"
import { VideoPlayButton } from "../../common/videoPlayButton/video-play-button"
import { theme } from "../../../tokens"

const VideoButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  @media (max-width: ${theme.breakpoints.lg}) {
    width: 40%;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    width: 30%;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    width: 50%;
    margin-top: -20px;
  }
`
class HowItWorks extends Component {
  constructor(props) {
    super()
    this.state = {
      isOpen: false,
      isDesktopOrTablet: true,
      isBrowser: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  componentDidMount() {
    this.updateViewport()
    window.addEventListener("resize", this.updateViewport)

    if (typeof window !== `undefined`) {
      this.setState({
        isBrowser: true,
      })
    }

    if (!document.getElementById("wistia_script")) {
      var wistiaScript = document.createElement("script")
      wistiaScript.id = "wistia_script"
      wistiaScript.type = "text/javascript"
      wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
      wistiaScript.async = true
      wistiaScript.popover = true
      document.body.appendChild(wistiaScript)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewport)
    this.handle && this.handle.remove()
  }

  updateViewport = () => {
    this.setState({ isDesktopOrTablet: window.innerWidth > 812 })
  }

  openModal() {
    this.setState({ isOpen: true })
  }
  render() {
    return (
      <ProgressiveImage
        preview={videobgmin}
        src={videobg}
        transitionTime={500}
        transitionFunction="ease"
        render={(src, style) => (
          <div
            className={styles.howItWorks}
            style={Object.assign(style, { backgroundImage: `url(${src})` })}
          >
            <div className={styles.whyVideo}>
              {this.state.isBrowser && (
                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="Eeh_r2Q3WJg"
                  onClose={() => this.setState({ isOpen: false })}
                />
              )}
            </div>

            <div className={styles.bannerContainer}>
              {this.state.isDesktopOrTablet ? (
                <button
                  className={styles.playWhyButton}
                  onClick={this.openModal}
                />
              ) : null}
              <div className={styles.bannerContainerText}>
                <h3>
                  How IronCAP
                  <TM /> Works
                </h3>
                <p>
                  IronCAP
                  <TM /> is a post-quantum cryptography (PQC) technology that is
                  safe against cyberattacks from classical computers as well as
                  future attacks from quantum computers which have excessive
                  computational power.
                </p>
                <p>
                  By combining both NIST-approved PQC algorithms as well as our
                  own patent-protected quantum-safe technology, IronCAP
                  <TM /> Toolkits is designed to be used in all kinds of
                  vertical solutions such as digital identity, email security,
                  remote access, cloud storage, IoT, blockchain, financial
                  transactions, etc.
                </p>
              </div>
              {this.state.isDesktopOrTablet ? null : (
                <button
                  className={styles.playWhyButton}
                  onClick={this.openModal}
                />
              )}
            </div>
          </div>
        )}
      />
    )
  }
}
export default HowItWorks
