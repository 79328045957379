import React from "react"
import VerticalApplicationsCard from "./verticalApplicationsCard"
import * as styles from "./verticalApplications.module.scss"
import { Location } from "@reach/router"

import email from "../../../../assets/email.png"
import signature from "../../../../assets/signature.png"
import blockchain from "../../../../assets/blockchain.png"
import vpn from "../../../../assets/vpn.png"
import passManage from "../../../../assets/pass_management.png"
import creditCard from "../../../../assets/credit-card.png"
import cloud from "../../../../assets/cloud.png"
import security from "../../../../assets/webpage.png"
import { TM } from "../../common/typography/trademark"

const verticalAppArray = [
  {
    title: "Emails/Files Encryption",
    description: (
      <>
        IronCAP
        <TM /> Post-Quantum Cryptography can be used to encrypt emails so that
        only the intended recipient can read them. It can also be used to
        provide quantum-safe file encryption for sensitive files such as
        personal finances and confidential documents.
      </>
    ),
    img: email,
  },
  {
    title: "Digital Signature",
    description: (
      <>
        IronCAP
        <TM /> Post-Quantum Cryptography can be used to digitally sign
        electronic documents such as emails or files to certify their
        authenticity to recipients.
      </>
    ),
    img: signature,
  },
  {
    title: "Blockchain",
    description: (
      <>
        IronCAP
        <TM /> allows all blockchain vendors including cryptocurrencies to
        digitally sign their new blocks to ensure security today, and in
        tomorrow's post-quantum world.
      </>
    ),
    img: blockchain,
  },
  {
    title: "Remote Access/VPN",
    description: (
      <>
        IronCAP
        <TM /> can help remote access and VPN vendors to guarantee the privacy
        of their remote access channels today, and in tomorrow's post-quantum
        world.
      </>
    ),
    img: vpn,
  },
  {
    title: "Password Management",
    description: (
      <>
        Password management vendors (e.g. one-time password) can use IronCAP
        <TM /> to generate passwords with unpredictability to safeguard against
        cyber attack today, and against tomorrow's quantum computers.
      </>
    ),
    img: passManage,
  },
  {
    title: "Credit Card Security",
    description: (
      <>
        Smart card on credit cards or smartphones using IronCAP
        <TM /> to encrypt data can provide security from today to tomorrow's
        post-quantum world.
      </>
    ),
    img: creditCard,
  },
  {
    title: "Cloud Storage",
    description: (
      <>
        IronCAP
        <TM /> Post-Quantum Cryptography can ensure data in a cloud storage be
        safe against hackers now and in the post-quantum world.
      </>
    ),
    img: cloud,
  },
  {
    title: "Website Security",
    description: (
      <>
        Vulnerability of web site identification and channel privacy today and
        in the post-quantum world can be plugged by using IronCAP
        <TM /> to encrypt their channel session keys.
      </>
    ),
    img: security,
  },
]

const VerticalApplications = ({ applicationsText }) => (
  <div className={styles.verticalApplicationsSection}>
    <Location>
      {({ location }) => {
        if (location.pathname.includes("ironcap-toolkits")) {
          return <h2>Vertical Applications Integration</h2>
        } else {
          return <h2>Vertical Applications</h2>
        }
      }}
    </Location>
    <Location>
      {({ location }) => {
        if (location.pathname.includes("ironcap-toolkits")) {
          return <p className={styles.applicationsText}>{applicationsText}</p>
        } else {
          return null
        }
      }}
    </Location>
    <div className={styles.cardsContainer}>
      {verticalAppArray.map(application => (
        <VerticalApplicationsCard
          title={application.title}
          description={application.description}
          img={application.img}
          key={application.title}
        />
      ))}
    </div>
  </div>
)

export default VerticalApplications
